import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { apiResponse } from "../authentication/models/auth";
import { Announcement } from "../models/announcement.model";
import { RequestData } from "../models/valuationComment.model";
import { Company, Employee, Person } from "../models/company.model";
import { Availability } from "../models/availability.model";
import { BidFeedback } from "../models/feedback.model";
import { Deal } from "../models/deal.model";
import { Move } from "../models/move.model";

@Injectable()
export class ApiService {
    private api_base: string = environment.apiUrl;

    constructor(private http: HttpClient) { }

    // Utility method to create headers
    private createHeaders(): HttpHeaders {
        return new HttpHeaders().set('Accept', 'application/json');
    }

    // Utility method to create query parameters
    private createParams(paramsObj: any): HttpParams {
        let params = new HttpParams();
        for (const key in paramsObj) {
            if (paramsObj[key] !== undefined && paramsObj[key] !== null) {
                params = params.append(key, String(paramsObj[key]));
            }
        }
        return params;
    }

    // Valuation endpoints

    // GET
    public getValuations(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const headers = this.createHeaders();
        const params = this.createParams({ limit, page, orderBy, filter, fields, defaultFilter, groupBy });

        return this.http.get<apiResponse>(`${this.api_base}/request/v1/valuation`, { headers, params });
    }

    public getValuation(valuationId: number, fields?: any) {
        const headers = this.createHeaders();
        const params = this.createParams({ fields });

        return this.http.get<apiResponse>(`${this.api_base}/request/v1/valuation/${valuationId}`, { headers, params });
    }

    public getTasks(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const headers = this.createHeaders();
        const params = this.createParams({ limit, page, orderBy, filter, fields, defaultFilter, groupBy });

        return this.http.get<apiResponse>(`${this.api_base}/request/v1/valuation/task`, { headers, params });
    }

    public getFeedbackTypes(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const headers = this.createHeaders();
        const params = this.createParams({ limit, page, orderBy, filter, fields, defaultFilter, groupBy });

        return this.http.get<apiResponse>(`${this.api_base}/request/v1/valuation/bid-feedback-type`, { headers, params });
    }

    public getSummaryTypes(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const headers = this.createHeaders();
        const params = this.createParams({ limit, page, orderBy, filter, fields, defaultFilter, groupBy });

        return this.http.get<apiResponse>(`${this.api_base}/request/v1/deal-summary-type`, { headers, params });
    }

    public getGaspedaalLink(valuationId: number) {
        const headers = this.createHeaders();

        return this.http.get<apiResponse>(`${this.api_base}/request/v1/valuation/${valuationId}/gaspedaal`, { headers });
    }

    public getAutotrackLink(valuationId: number) {
        const headers = this.createHeaders();

        return this.http.get<apiResponse>(`${this.api_base}/request/v1/valuation/${valuationId}/autotrack`, { headers });
    }

    // POST
    public createValuationComment(valuationId: number, data: RequestData) {
        const headers = this.createHeaders();

        return this.http.post<apiResponse>(`${this.api_base}/request/v1/valuation/${valuationId}/comment`, data, { headers });
    }

    public bidFeedback(valuationId: number, data: BidFeedback) {
        const headers = this.createHeaders();

        return this.http.post<apiResponse>(`${this.api_base}/request/v1/valuation/${valuationId}/bid-feedback`, data, { headers });
    }

    // PUT
    public setAvailability(valuationId: number, data: Availability) {
        const headers = this.createHeaders();

        return this.http.put<apiResponse>(`${this.api_base}/request/v1/valuation/${valuationId}/availability`, data, { headers });
    }

    public setDeal(valuationId: number, data: Deal) {
        const headers = this.createHeaders();

        return this.http.put<apiResponse>(`${this.api_base}/request/v1/valuation/${valuationId}/deal`, data, { headers });
    }

    public moveValuation(valuationId: number, data: Move) {
        const headers = this.createHeaders();

        return this.http.put<apiResponse>(`${this.api_base}/request/v1/valuation/${valuationId}/move`, data, { headers });
    }

    // VehicleDescription endpoints

    // GET
    public getVehicleDescriptions(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const headers = this.createHeaders();
        const params = this.createParams({ limit, page, orderBy, filter, fields, defaultFilter, groupBy });

        return this.http.get<apiResponse>(`${this.api_base}/request/v1/vehicle-description`, { headers, params });
    }


    // CRM endpoints

    // GET
    public getCompanies(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const headers = this.createHeaders();
        const params = this.createParams({ limit, page, orderBy, filter, fields, defaultFilter, groupBy });

        return this.http.get<Company>(`${this.api_base}/crm/v1/company`, { headers, params });
    }

    public getEmployees(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const headers = this.createHeaders();
        const params = this.createParams({ limit, page, orderBy, filter, fields, defaultFilter, groupBy });

        return this.http.get<Company>(`${this.api_base}/crm/v1/employee`, { headers, params });
    }

    public getCompanyId(valuationId: number, fields?: any) {
        const headers = this.createHeaders();
        const params = this.createParams({ fields });

        return this.http.get<apiResponse>(`${this.api_base}/crm/v1/company/${valuationId}`, { headers, params });
    }

    public getEmployee(employeeId: number, fields?: any) {
        const headers = this.createHeaders();
        const params = this.createParams({ fields });

        return this.http.get<apiResponse>(`${this.api_base}/crm/v1/employee/${employeeId}`, { headers, params });
    }

    public getPerson(personId: number, fields?: any) {
        const headers = this.createHeaders();
        const params = this.createParams({ fields });

        return this.http.get<apiResponse>(`${this.api_base}/crm/v1/person/${personId}`, { headers, params });
    }

    // PUT
    public updateCompany(companyId: number, data: Company) {
        const headers = this.createHeaders();

        return this.http.put<apiResponse>(`${this.api_base}/crm/v1/company/${companyId}`, data, { headers });
    }

    public updatePerson(personId: number, data: Person | any) {
        const headers = this.createHeaders();

        return this.http.put<apiResponse>(`${this.api_base}/crm/v1/person/${personId}`, data, { headers });
    }

    // DELETE
    public deleteEmployee(employeeId: number) {
        const headers = this.createHeaders();

        return this.http.delete<apiResponse>(`${this.api_base}/crm/v1/employee/${employeeId}`, { headers });
    }

    // POST
    public createEmployee(data: Employee) {
        const headers = this.createHeaders();

        return this.http.post<apiResponse>(`${this.api_base}/crm/v1/employee`, data, { headers });
    }

    public createPerson(data: Person) {
        const headers = this.createHeaders();

        return this.http.post<apiResponse>(`${this.api_base}/crm/v1/person`, data, { headers });
    }

    // Communication endpoints

    // GET
    public getAnnouncements(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const headers = this.createHeaders();
        const params = this.createParams({ limit, page, orderBy, filter, fields, defaultFilter, groupBy });

        return this.http.get<Announcement>(`${this.api_base}/communication/v1/announcement`, { headers, params });
    }

    // Request endpoints

    // GET

    public getBodyTypes(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const headers = this.createHeaders();
        const params = this.createParams({ limit, page, orderBy, filter, fields, defaultFilter, groupBy });

        return this.http.get<apiResponse>(`${this.api_base}/request/v1/body-type`, { headers, params });
    }

    public getDriveTypes(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const headers = this.createHeaders();
        const params = this.createParams({ limit, page, orderBy, filter, fields, defaultFilter, groupBy });

        return this.http.get<apiResponse>(`${this.api_base}/request/v1/drive-type`, { headers, params });
    }

    public getFuelTypes(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const headers = this.createHeaders();
        const params = this.createParams({ limit, page, orderBy, filter, fields, defaultFilter, groupBy });

        return this.http.get<apiResponse>(`${this.api_base}/request/v1/fuel-type`, { headers, params });
    }

    public getMakes(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const headers = this.createHeaders();
        const params = this.createParams({ limit, page, orderBy, filter, fields, defaultFilter, groupBy });

        return this.http.get<apiResponse>(`${this.api_base}/request/v1/make`, { headers, params });
    }

    public getModels(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const headers = this.createHeaders();
        const params = this.createParams({ limit, page, orderBy, filter, fields, defaultFilter, groupBy });

        return this.http.get<apiResponse>(`${this.api_base}/request/v1/model`, { headers, params });
    }

    public getTransmissionTypes(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const headers = this.createHeaders();
        const params = this.createParams({ limit, page, orderBy, filter, fields, defaultFilter, groupBy });

        return this.http.get<apiResponse>(`${this.api_base}/request/v1/transmission-type`, { headers, params });
    }

    public getVehicleTypes(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const headers = this.createHeaders();
        const params = this.createParams({ limit, page, orderBy, filter, fields, defaultFilter, groupBy });

        return this.http.get<apiResponse>(`${this.api_base}/request/v1/vehicle-type`, { headers, params });
    }
}
